import { type FirebaseApp, initializeApp } from "firebase/app";
import { env } from "./env.mjs";
import { isSupported } from "firebase/messaging";

const firebaseConfig = {
  apiKey: env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: env.NEXT_PUBLIC_FIREBASE_APP_ID,
};

let firebaseApp: FirebaseApp | null = null;
let initializationPromise: Promise<FirebaseApp | null> | null = null;

async function initializeFirebase(): Promise<FirebaseApp | null> {
  const areRequiredBrowserAPIsSupported = await isSupported();

  if (areRequiredBrowserAPIsSupported) {
    console.log("🟢 Firebase APIs are supported. Initializing Firebase App...");
    firebaseApp = initializeApp(firebaseConfig);
    console.log("🟢 Firebase App initialized", !!firebaseApp);
    return firebaseApp;
  }

  return null;
}

// Export a getter function that handles initialization if needed
export async function getFirebaseApp(): Promise<FirebaseApp | null> {
  if (firebaseApp) return firebaseApp;

  // If initialization is in progress, return the existing promise
  if (initializationPromise) return initializationPromise;

  // Start initialization and store the promise
  initializationPromise = initializeFirebase();

  try {
    // Wait for initialization to complete
    firebaseApp = await initializationPromise;
    return firebaseApp;
  } catch (error) {
    console.error("Failed to initialize Firebase:", error);
    return null;
  } finally {
    // Clear the initialization promise
    initializationPromise = null;
  }
}
