import { api } from "./api";

const tierCanUseFeature = {
  events: {
    silver: true,
    gold: true,
    bronzeV3: false,
    silverV3: true,
    goldV3: true,
  },
  "alerts:openingLine": {
    silver: false,
    gold: true,
    bronzeV3: false,
    silverV3: false,
    goldV3: true,
  },
  "alerts:limitChange": {
    silver: false,
    gold: true,
    bronzeV3: false,
    silverV3: false,
    goldV3: true,
  },
  "pushNotifications:alerts": {
    silver: false,
    gold: true,
    bronzeV3: false,
    silverV3: false,
    goldV3: true,
  },
  "betTracker:liveEV": {
    silver: false,
    gold: true,
    bronzeV3: false,
    silverV3: false,
    goldV3: true,
  },
  "betTracker:closingLine": {
    silver: false,
    gold: true,
    bronzeV3: false,
    silverV3: false,
    goldV3: true,
  },
} as const;

export const tierQuotas = {
  "alerts:criteria": {
    silver: 9,
    gold: 20,
    bronzeV3: 1,
    silverV3: 9,
    goldV3: 20,
  },
} as const;

/**
 * Hook that gets subscription tier and returns an object of features with true or false values
 *
 */
export const useCanUserAccess = () => {
  const { data: subscription } = api.paymentsRouter.getSubscription.useQuery();

  const tier = subscription?.tier;

  if (!tier)
    return {
      features: {
        events: false,
        "alerts:openingLine": false,
        "alerts:limitChange": false,
        "pushNotifications:alerts": false,
        "betTracker:liveEV": false,
        "betTracker:closingLine": false,
      },
      quotas: {
        "alerts:criteria": 0,
      },
    };

  const features = Object.entries(tierCanUseFeature).reduce(
    (acc, [feature, tiers]) => ({
      ...acc,
      [feature]: tiers[tier],
    }),
    {} as Record<keyof typeof tierCanUseFeature, boolean>
  );

  const quotas = Object.entries(tierQuotas).reduce(
    (acc, [feature, tiers]) => ({
      ...acc,
      [feature]: tiers[tier],
    }),
    {} as Record<keyof typeof tierQuotas, number>
  );

  return { features, quotas };
};
