import { useUser } from "@clerk/nextjs";
import { Analytics, type AnalyticsProps } from "@dub/analytics/react";
import { useEffect } from "react";
import { api } from "../../utils/api";

function getCookie(name: string): string | null {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(";").shift() || null;
  return null;
}

export function DubAnalytics(props: AnalyticsProps) {
  const { user } = useUser();
  const trackLeadMutation = api.dub.lead.useMutation();

  useEffect(() => {
    if (!user || user.publicMetadata.dubClickId) return;

    const dubId = getCookie("dub_id");

    if (dubId) {
      // if the user is loaded but hasn't been persisted to Dub yet, track the lead event
      trackLeadMutation
        .mutateAsync({
          dubClickId: dubId,
        })
        .then((res) => {
          if (res.success) {
            console.log("Lead event registered successfully");
            // remove cookie
            document.cookie =
              "dub_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          } else {
            console.error("Failed to register lead event");
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
    // Adding trackLeadMutation to the dependency array causes the component to re-render infinitely
  }, [user]);

  return <Analytics {...props} />;
}
